import { Tabs, Tab } from '@mui/material';
import { IllusTdPHistory } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ROLE } from 'src/Redux/Types';
import { ACTIONS } from '../HistoryModal.types';
import HistoryModalHeader from '../HistoryModalHeader/HistoryModalHeader.component';
import { ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './HistoryMobileModal.types';
import { historyTab } from '../HistoryModal.component';
import { LocationState } from 'src/Services/Routing';
import { useHistory, useLocation } from 'react-router-dom';
import { historyTabStyle, historyTabsStyles } from './HistoryMobileModal.styles';
import styles from './HistoryMobileModal.module.scss';

const HistoryMobileModal = ({
  target,
  selectedTab,
  onTabChange,
  onClose,
  displayedSection,
}: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const screenHeight = window.innerHeight;
  return (
    <ModalDeprecated
      open
      icon={<IllusTdPHistory />}
      title="historique des actions"
      onClose={onClose}
      overlayStyle={{ paddingBottom: '0rem' }}
      contentStyle={{
        height: `${screenHeight - 40}px`,
      }}
      containerClassName={styles.modalContentClassname}
    >
      <HistoryModalHeader target={target} />
      <Tabs
        value={selectedTab}
        variant="scrollable"
        scrollButtons={false}
        sx={historyTabsStyles}
        onChange={(_, menuItem: ACTIONS) => {
          if (menuItem !== selectedTab) {
            onTabChange(menuItem);
            history.push({
              pathname: `/history/${menuItem}`,
              state: { modalParameters: { target }, background: location.state?.background },
            });
          }
        }}
      >
        {Object.values(ACTIONS)
          .filter(
            action =>
              !(target?.role === ROLE.CANDIDATE && action === ACTIONS.PRODUCTION_IMPACT) &&
              !(
                target?.role === ROLE.CONTACT &&
                [ACTIONS.ABSENCES, ACTIONS.SECURITE].includes(action)
              )
          )
          .map(item => (
            <Tab key={item} label={historyTab[item]} value={item} sx={historyTabStyle} />
          ))}
      </Tabs>
      {displayedSection}
    </ModalDeprecated>
  );
};

export default HistoryMobileModal;
