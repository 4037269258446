import { useState } from 'react';
import WorkplaceHeader from 'src/Components/WorkplaceHeader/WorkplaceHeader.component';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styles from './MobileTaskSection.module.scss';
import { MOBILE_TASK_DISPLAYED_SECTION } from './MobileTaskSection.types';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { weekToString } from 'src/Containers/AgendaSection/AgendaWeekSection/AgendaWeekSection.component';
import classnames from 'classnames';
import { basicTabsStyles, basicTabStyles } from './MobileTaskSection.styles';
import MobileCommunicationMatrix from 'src/Containers/MobileCommunicationMatrix/MobileCommunicationMatrix.component';
import { useSection } from 'src/Hooks/Section/useSection';
import { animate } from 'src/Utils/animate';

const TITLE = 'tâches en attente';

const taskMenu = [
  { title: 'matrice de com.', displayedSection: MOBILE_TASK_DISPLAYED_SECTION.COMMUNICATION },
  { title: 'actions à traiter', displayedSection: MOBILE_TASK_DISPLAYED_SECTION.ACTIONS },
];

const MobileTaskSection = () => {
  const [taskSection, setTaskSection] = useState(MOBILE_TASK_DISPLAYED_SECTION.COMMUNICATION);
  const [selectedWeek, setSelectedWeek] = useState(moment());
  const badgesContainers = document.getElementById('badgesContainers');
  const section = useSection();
  return (
    <div className={styles.container}>
      <WorkplaceHeader title={TITLE} doAnimation={true} />
      <div
        className={styles.badgesContainer}
        id="badgesContainers"
        style={{
          minHeight: `${animate(1.5, 0, section?.titleKeyframe ?? 0)}rem`,
          height: `${animate(1.5, 0, section?.titleKeyframe ?? 0)}rem`,
        }}
      >
        <BadgeDeprecated
          value={`semaine du ${weekToString(moment())}`}
          className={classnames(styles.badge, {
            [styles.selectedBadge]: moment().dayOfYear() === selectedWeek.dayOfYear(),
          })}
          onClick={() => {
            setSelectedWeek(moment());
            badgesContainers?.scrollTo({ left: 0, behavior: 'smooth' });
          }}
        />
        <BadgeDeprecated
          value={`semaine du ${weekToString(moment().add(7, 'days'))}`}
          className={classnames(styles.badge, {
            [styles.selectedBadge]:
              moment().add(7, 'days').dayOfYear() === selectedWeek.dayOfYear(),
          })}
          onClick={() => {
            setSelectedWeek(moment().add(7, 'days'));
            badgesContainers?.scrollTo({ left: 32, behavior: 'smooth' });
          }}
        />
      </div>
      <Tabs
        value={taskSection}
        onChange={(_, newValue) => {
          if (newValue !== taskSection) {
            setTaskSection(newValue);
          }
        }}
        sx={basicTabsStyles}
      >
        {taskMenu.map(tab => (
          <Tab
            key={tab.title}
            label={tab.title}
            sx={{
              ...basicTabStyles,
              width: '50%',
            }}
          />
        ))}
      </Tabs>
      <div className={styles.separator} />
      <MobileCommunicationMatrix week={selectedWeek} toDoDisplayedSection={taskSection} />
    </div>
  );
};

export default MobileTaskSection;
