import React from 'react';
import Card from '../Card';
import List from '../List';

import styles from './Grid.module.scss';
import { Props } from './Grid.types';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const Grid = Object.assign(
  (props: Props) => {
    const isMobile = useMediaQuery(responsiveQuery);

    return (
      <List
        {...props}
        subGroupStyle={isMobile ? styles.mobileCardRow : styles.cardRow}
        itemRenderer={Card}
      />
    );
  },
  { Item: Card }
);

export default Grid;
