import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { Props } from './AbsenceFormModal.types';
import styles from './AbsenceFormModal.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import {
  AbsenceOnMonth,
  AbsenceReason,
  CandidateOnMission,
  EnumAbsenceTimeInterval,
} from 'src/Services/API';
import {
  Animation,
  Button,
  DatePickerInputDeprecated,
  FetchButton,
  ModalDeprecated,
  TextArea,
  TextInput,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusTdpAbsence,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SelectAbsenceReason from 'src/Containers/Selects/SelectAbsenceReason';
import AbsenceTimeIntervals from '../AbsenceTimeIntervals';
import { useFetchAbsenceReasons } from '../../Hooks/Absence';
import SelectService from 'src/Containers/Selects/SelectService';
import { useFetchAllCandidates } from 'src/Hooks/Candidates';
import SelectCandidate from 'src/Containers/Selects/SelectCandidate';
import AbsenceDeletionWarningModal from '../AbsenceDeletionWarningModal';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const UNHAUTORIZED_ABSENCE = 'ABN';

const getMonthsFromDates = (params: { startDate: Date; endDate: Date }): string[] => {
  const startMoment = moment(params.startDate);
  const endMoment = moment(params.endDate);
  const dates = [];
  while (
    startMoment.get('month') + startMoment.get('year') * 12 <=
    endMoment.get('month') + endMoment.get('year') * 12
  ) {
    dates.push(startMoment.format('MMMM YYYY'));
    startMoment.add(1, 'month');
  }
  return dates;
};

const handleAbsenceTableFromMonths = (params: {
  months: string[];
  table?: AbsenceOnMonth[];
}): AbsenceOnMonth[] => {
  return params.months.map(month => {
    return {
      month: month,
      absence: params.table?.find(item => item.month === month)?.absence ?? 0,
    };
  });
};

const AbsenceFormModal = ({
  absence,
  target,
  onValidate,
  fetchStatus,
  isEditionModal,
  onDelete,
  deleteFetchStatus,
  open,
  title,
}: Props) => {
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const currentAgency = useSelector(getCurrentAgency);
  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  const isMobile = useMediaQuery(responsiveQuery);
  const absenceReasons = useFetchAbsenceReasons();
  const candidatesInPool = useFetchAllCandidates();
  const [animation, activateAnimation] = useState(true);

  const [selectedTimeInterval, setSelectedTimeInterval] = useState<
    EnumAbsenceTimeInterval | undefined
  >(absence?.timeInterval);
  const [startDate, setStartDate] = useState<Date | undefined>(absence?.startDate);
  const [endDate, setEndDate] = useState<Date | undefined>(absence?.endDate);
  const [absenceReason, setAbsenceReason] = useState<AbsenceReason | undefined>(
    absenceReasons.find(reason => reason.id === absence?.absenceReason)
  );
  const [absenceNotifiedByCandidate, setAbsenceNotifiedByCandidate] = useState(
    absence?.informedByCandidate ?? false
  );

  const [montlhyEffectiveAbsence, setMonthlyEffectiveAbsence] = useState<
    { month: string; absence: string }[]
  >([]);
  const [montlhyEffectiveReplacement, setMonthlyEffectiveReplacement] = useState<
    AbsenceOnMonth[] | undefined
  >();
  const [replacementRequired, setReplacementRequired] = useState(
    absence?.replacementRequired ?? false
  );
  const [hasBeenJustify, setHasBeenJustify] = useState(absence?.hasBeenJustify ?? false);
  const [replacementStartDate, setReplacementStartDate] = useState<Date | undefined>(
    absence?.replacementStartDate
  );
  const [replacementEndDate, setReplacementEndDate] = useState<Date | undefined>(
    absence?.replacementEndDate
  );
  const [comment, setComment] = useState(absence?.comment);
  const [replacementCandidate, setReplacementCandidate] = useState<CandidateOnMission | null>(
    candidatesInPool?.find(
      candidate => candidate.candidateId === absence?.replacementCandidateId
    ) ?? null
  );

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const initServiceId = isEditionModal
    ? absence?.serviceId
    : candidatesInPool?.find(candidate => candidate.candidateId === target.id)?.service?.serviceId;

  const [absenceService, setAbsenceService] = useState<string | undefined>(initServiceId);

  const toAbsenceOnMonth = useCallback((state: { month: string; absence: string }[]) => {
    return state.map(value => {
      const absenceValue = parseFloat(value.absence);
      return {
        month: value.month,
        absence: isNaN(absenceValue) ? 0 : absenceValue,
      };
    });
  }, []);

  const fromAbsenceOnMonth = useCallback((state: AbsenceOnMonth[]) => {
    return state.map(value => {
      return { month: value.month, absence: `${value.absence}` };
    });
  }, []);

  const replacementDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    activateAnimation(!animation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [montlhyEffectiveReplacement]);

  const reset = useCallback(() => {
    setSelectedTimeInterval(absence?.timeInterval);
    setAbsenceService(initServiceId);
    setAbsenceReason(absenceReasons.find(reason => reason.id === absence?.absenceReason));
    setAbsenceNotifiedByCandidate(absence?.informedByCandidate ?? false);
    setMonthlyEffectiveAbsence(
      absence?.effectiveAbsenceTime.map(absenceOnMonth => {
        return {
          month: absenceOnMonth.month,
          absence: `${absenceOnMonth.absence}`,
        };
      }) ?? []
    );
    setMonthlyEffectiveReplacement(absence?.effectiveReplacementTime ?? undefined);
    setStartDate(absence?.startDate);
    setEndDate(absence?.endDate);
    setReplacementRequired(absence?.replacementRequired ?? false);
    setReplacementStartDate(absence?.replacementStartDate);
    setReplacementEndDate(absence?.replacementEndDate);
    setHasBeenJustify(absence?.hasBeenJustify ?? false);
    setComment(absence?.comment);
    setReplacementCandidate(
      candidatesInPool?.find(
        candidate => candidate.candidateId === absence?.replacementCandidateId
      ) ?? null
    );
  }, [
    absence?.absenceReason,
    absence?.effectiveAbsenceTime,
    absence?.endDate,
    absence?.informedByCandidate,
    absence?.effectiveReplacementTime,
    absence?.replacementEndDate,
    absence?.replacementRequired,
    absence?.replacementStartDate,
    absence?.hasBeenJustify,
    absence?.startDate,
    absence?.timeInterval,
    absence?.comment,
    absence?.replacementCandidateId,
    absenceReasons,
    initServiceId,
    candidatesInPool,
  ]);

  useEffect(reset, [
    reset,
    absenceReasons,
    absence?.absenceReason,
    absence?.effectiveAbsenceTime,
    absence?.endDate,
    absence?.informedByCandidate,
    absence?.effectiveReplacementTime,
    absence?.replacementEndDate,
    absence?.replacementRequired,
    absence?.replacementStartDate,
    absence?.hasBeenJustify,
    absence?.startDate,
    absence?.timeInterval,
    absence?.comment,
    absence?.replacementCandidateId,
    initServiceId,
    candidatesInPool,
  ]);

  return (
    <ModalDeprecated
      open={open ?? true}
      title={title}
      subtitle={target?.name}
      onClose={() => {
        onClose();
      }}
      icon={
        <div className={styles.modalIcon}>
          <IllusTdpAbsence />
        </div>
      }
      footerActionsLeft={
        isEditionModal
          ? [
              <Button.Tertiary
                onClick={() => setOpenDeleteModal(true)}
                leftIcon={!isMobile ? <Trashcan /> : undefined}
                text="supprimer"
              />,
            ]
          : []
      }
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={onClose}>annuler</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        ),
        <FetchButton
          title="valider"
          fetchStatus={fetchStatus}
          errorTitle="réessayer"
          key="validate-button"
          disabled={
            target === undefined ||
            absenceService === undefined ||
            selectedTimeInterval === undefined ||
            startDate === undefined ||
            endDate === undefined ||
            absenceReason === undefined ||
            montlhyEffectiveAbsence.map(month => month.absence).includes(String(NaN)) ||
            moment(endDate).isBefore(moment(startDate)) ||
            (replacementRequired &&
              (replacementStartDate === undefined ||
                replacementEndDate === undefined ||
                moment(replacementEndDate).isBefore(moment(replacementStartDate)) ||
                moment(replacementEndDate).isAfter(moment(endDate)) ||
                moment(replacementStartDate).isBefore(moment(startDate)) ||
                montlhyEffectiveReplacement === undefined ||
                montlhyEffectiveAbsence
                  .map(
                    absence =>
                      parseFloat(absence.absence) -
                      (montlhyEffectiveReplacement.find(
                        replacement => replacement.month === absence.month
                      )?.absence ?? 0)
                  )
                  .filter(item => item < 0).length > 0 ||
                montlhyEffectiveReplacement.map(replacement => replacement.absence).includes(NaN)))
          }
          onClick={() => {
            return onValidate({
              qualificationName: target?.qualification?.name,
              qualificationId: target?.qualification?.id,
              companyId: currentCompanyId ?? '',
              candidateName: target.name,
              agencyId: currentAgency,
              serviceId: absenceService ?? initServiceId,
              hasBeenJustify:
                absenceReason?.id === UNHAUTORIZED_ABSENCE ? hasBeenJustify : undefined,
              candidateId: target?.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              startDate: startDate!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              endDate: endDate!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              timeInterval: selectedTimeInterval!,
              effectiveAbsenceTime: toAbsenceOnMonth(montlhyEffectiveAbsence),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              absenceReason: absenceReason!.id ?? '',
              informedByCandidate: absenceNotifiedByCandidate,
              replacementRequired: replacementRequired,
              replacementStartDate: replacementStartDate,
              replacementEndDate: replacementEndDate,
              effectiveReplacementTime: replacementRequired
                ? montlhyEffectiveReplacement
                : undefined,
              localId: absence?.localId,
              comment: comment,
              replacementCandidateId: replacementCandidate?.candidateId,
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.form}>
        <WithLightTitle title="qualification">{target?.qualification?.name}</WithLightTitle>

        <div className={classnames(styles.line, { [styles.mobileLine]: isMobile })}>
          <WithLightTitle title="absence à partir de" className={styles.datePickerContainer}>
            <DatePickerInputDeprecated
              onSelectDate={date => {
                setStartDate(date);
                if (date && endDate && !moment(date).isAfter(endDate)) {
                  const months = getMonthsFromDates({ startDate: date, endDate: endDate });
                  setMonthlyEffectiveAbsence(
                    fromAbsenceOnMonth(
                      handleAbsenceTableFromMonths({
                        months,
                        table: toAbsenceOnMonth(montlhyEffectiveAbsence),
                      })
                    )
                  );
                } else if (date && endDate && moment(date).isAfter(endDate)) {
                  setMonthlyEffectiveAbsence([]);
                }
              }}
              date={startDate}
            />
          </WithLightTitle>
          <WithLightTitle title="absence jusqu'au" className={styles.datePickerContainer}>
            <DatePickerInputDeprecated
              onSelectDate={date => {
                setEndDate(date);
                if (date && startDate && !moment(date).isBefore(startDate)) {
                  const months = getMonthsFromDates({ startDate: startDate, endDate: date });
                  setMonthlyEffectiveAbsence(
                    fromAbsenceOnMonth(
                      handleAbsenceTableFromMonths({
                        months,
                        table: toAbsenceOnMonth(montlhyEffectiveAbsence),
                      })
                    )
                  );
                } else if (date && startDate && moment(date).isBefore(startDate)) {
                  setMonthlyEffectiveAbsence([]);
                }
              }}
              date={endDate}
            />
          </WithLightTitle>
        </div>

        {montlhyEffectiveAbsence.map((month, idx) => {
          return (
            <div className={styles.line} key={`${month} - ${idx} - absence`}>
              <WithLightTitle
                className={styles.monthLabelContainer}
                title={`${idx + 1}${idx === 0 ? 'er' : 'e'} mois d'absence`}
              >
                <p className={styles.monthLabel}>{month.month}</p>
              </WithLightTitle>
              <WithLightTitle
                title="durée d'absence au 100e d'heure"
                className={classnames(styles.monthLabelContainer, {
                  [styles.mobileMonthLabelContainer]: isMobile,
                })}
              >
                <TextInput
                  type="text"
                  inputMode="numeric"
                  placeholder={'0'}
                  value={`${month.absence}`}
                  onChange={e => {
                    const monthCopy = [...montlhyEffectiveAbsence];
                    const absence = e.currentTarget.value.replace(',', '.');
                    monthCopy.splice(idx, 1, {
                      month: month.month,
                      absence: absence,
                    });
                    setMonthlyEffectiveAbsence(monthCopy);
                  }}
                />
              </WithLightTitle>
            </div>
          );
        })}

        <WithLightTitle title={"type d'équipe"}>
          <AbsenceTimeIntervals
            selectedTimeInterval={selectedTimeInterval}
            onTimeIntervalSelect={setSelectedTimeInterval}
          />
        </WithLightTitle>

        <WithLightTitle title={"motif d'une absence"}>
          <SelectAbsenceReason selection={absenceReason} onSelectionChange={setAbsenceReason} />
        </WithLightTitle>

        <TextArea
          className={styles.textArea}
          value={comment}
          onChange={e => setComment(e.currentTarget.value)}
          placeholder="commentaires / action à mener"
        />

        <div className={styles.toggleSwitchLine}>
          <div className={styles.line}>absence prévenue par l'intérimaire ?</div>
          <ToggleSwitch
            onCheckStatusChange={setAbsenceNotifiedByCandidate}
            checked={absenceNotifiedByCandidate}
          />
        </div>

        {absenceReason?.id === UNHAUTORIZED_ABSENCE && (
          <div className={styles.toggleSwitchLine}>
            <div className={styles.line}>justificatif reçu </div>
            <ToggleSwitch onCheckStatusChange={setHasBeenJustify} checked={hasBeenJustify} />
          </div>
        )}
        <WithLightTitle title={"service lié à l'absence"}>
          <SelectService selected={absenceService} onChange={value => setAbsenceService(value)} />
        </WithLightTitle>

        <div className={styles.toggleSwitchLine}>
          <div className={styles.line}>remplacement requis</div>
          <ToggleSwitch
            onCheckStatusChange={setReplacementRequired}
            checked={replacementRequired}
          />
        </div>

        <Animation.Unroll visible={replacementRequired}>
          <div className={styles.form} ref={replacementDiv}>
            <WithLightTitle title="remplacer par">
              <SelectCandidate
                selectedCandidate={
                  replacementCandidate ?? {
                    candidateId: undefined,
                    name: undefined,
                    firstName: undefined,
                  }
                }
                onCandidateSelect={setReplacementCandidate}
                blackListCandidateIds={[target.id]}
              />
            </WithLightTitle>

            <div className={classnames(styles.line, { [styles.mobileLine]: isMobile })}>
              <WithLightTitle
                title="remplacement à partir du"
                className={styles.datePickerContainer}
              >
                <DatePickerInputDeprecated
                  onSelectDate={date => {
                    setReplacementStartDate(date);
                    if (date && replacementEndDate && !moment(date).isAfter(replacementEndDate)) {
                      const months = getMonthsFromDates({
                        startDate: date,
                        endDate: replacementEndDate,
                      });
                      setMonthlyEffectiveReplacement(
                        handleAbsenceTableFromMonths({ months, table: montlhyEffectiveReplacement })
                      );
                    } else if (
                      date &&
                      replacementEndDate &&
                      moment(date).isAfter(replacementEndDate)
                    ) {
                      setMonthlyEffectiveReplacement([]);
                    }
                  }}
                  date={replacementStartDate}
                />
              </WithLightTitle>
              <WithLightTitle title="remplacement jusqu'au" className={styles.datePickerContainer}>
                <DatePickerInputDeprecated
                  onSelectDate={date => {
                    setReplacementEndDate(date);
                    if (
                      date &&
                      replacementStartDate &&
                      !moment(date).isBefore(replacementStartDate)
                    ) {
                      const months = getMonthsFromDates({
                        startDate: replacementStartDate,
                        endDate: date,
                      });
                      setMonthlyEffectiveReplacement(
                        handleAbsenceTableFromMonths({ months, table: montlhyEffectiveReplacement })
                      );
                    } else if (
                      date &&
                      replacementStartDate &&
                      moment(date).isBefore(replacementStartDate)
                    ) {
                      setMonthlyEffectiveReplacement([]);
                    }
                  }}
                  date={replacementEndDate}
                />
              </WithLightTitle>
            </div>
            {montlhyEffectiveReplacement?.map((month, idx) => {
              return (
                <div className={styles.mobileReplacementContainer}>
                  <div className={styles.line} key={`${month} - ${idx} - replacement`}>
                    <WithLightTitle
                      className={styles.monthLabelContainer}
                      title={`${idx + 1}${idx === 0 ? 'er' : 'e'} mois d'absence`}
                    >
                      <p className={styles.monthLabel}>{month.month}</p>
                    </WithLightTitle>
                    <WithLightTitle
                      title="temps de remplacement en 100e"
                      className={classnames(styles.monthLabelContainer, {
                        [styles.mobileMonthLabelContainer]: isMobile,
                      })}
                    >
                      <TextInput
                        type="number"
                        step="0.01"
                        value={month.absence}
                        onChange={e => {
                          const monthCopy = [...montlhyEffectiveReplacement];
                          monthCopy.splice(idx, 1, {
                            month: month.month,
                            absence: parseFloat(e.currentTarget.value),
                          });
                          setMonthlyEffectiveReplacement(monthCopy);
                        }}
                      />
                    </WithLightTitle>
                    {!isMobile && (
                      <WithLightTitle title="temps d'absence subi au 100e">
                        {montlhyEffectiveAbsence.find(item => item.month === month.month) &&
                        !isNaN(month.absence)
                          ? (
                              (toAbsenceOnMonth(montlhyEffectiveAbsence).find(
                                item => item.month === month.month
                              )?.absence ?? 0) - (month.absence ?? 0)
                            ).toFixed(2)
                          : '-'}
                      </WithLightTitle>
                    )}
                  </div>
                  {isMobile && (
                    <WithLightTitle
                      title="temps d'absence subi au 100e"
                      className={styles.mobileAbsenceResult}
                    >
                      {montlhyEffectiveAbsence.find(item => item.month === month.month) &&
                      !isNaN(month.absence)
                        ? (
                            (toAbsenceOnMonth(montlhyEffectiveAbsence).find(
                              item => item.month === month.month
                            )?.absence ?? 0) - (month.absence ?? 0)
                          ).toFixed(2)
                        : '-'}
                    </WithLightTitle>
                  )}
                </div>
              );
            })}
          </div>
        </Animation.Unroll>
      </div>

      {deleteFetchStatus !== undefined && onDelete !== undefined && (
        <AbsenceDeletionWarningModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          fetchStatus={deleteFetchStatus}
          onSuccess={() => {
            onClose();
          }}
          onDelete={() => absence?.absenceId && onDelete(absence?.absenceId)}
        />
      )}
    </ModalDeprecated>
  );
};

export default AbsenceFormModal;
