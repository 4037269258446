import React from 'react';

import { Props } from './AbsenceTimeIntervals.types';
import styles from './AbsenceTimeIntervals.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { EnumAbsenceTimeInterval } from 'src/Services/API';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const AbsenceTimeIntervals = ({ onTimeIntervalSelect, selectedTimeInterval }: Props) => {
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {Object.entries(EnumAbsenceTimeInterval).map(item =>
        item[1] === selectedTimeInterval ? (
          <Button.Primary
            className={classnames(styles.primaryButton, { [styles.mobilePrimaryButton]: isMobile })}
          >
            {item[0]}
          </Button.Primary>
        ) : (
          <Button.Secondary
            className={classnames(styles.unselectedButton, {
              [styles.mobileUnselectedButton]: isMobile,
            })}
            onClick={() => onTimeIntervalSelect(item[1])}
          >
            {item[0]}
          </Button.Secondary>
        )
      )}
    </div>
  );
};

export default AbsenceTimeIntervals;
