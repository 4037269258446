import { SelectionIconButton } from '@randstad-lean-mobile-factory/react-components-core';
import useFetchPoolSenders from 'src/Hooks/Repositories/useFetchPoolSenders';
import styles from './PoolExitSenderSelector.module.scss';
import { Props } from './PoolExitSenderSelector.types';
import { IllusAgency, IllusCustomer, IllusWorker } from 'src/Assets';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const iconMapping: { [id: string]: JSX.Element } = {
  'POOLEXIT_SEND-1': <IllusAgency />,
  'POOLEXIT_SEND-2': <IllusCustomer />,
  'POOLEXIT_SEND-3': <IllusWorker />,
};

const PoolExitSenderSelector = ({ selected, setSelected }: Props) => {
  const { data: senders, isLoading, isSuccess } = useFetchPoolSenders();
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {isLoading &&
        new Array(3).fill(undefined).map((_, index) => {
          return <SelectionIconButton.Loader key={index} />;
        })}
      {isSuccess &&
        senders?.map(sender => (
          <SelectionIconButton
            key={sender.id}
            className={classnames(styles.item, { [styles.mobileItem]: isMobile })}
            labelClassName={classnames(styles.label, { [styles.mobileLabel]: isMobile })}
            label={sender.label}
            icon={iconMapping[sender.id]}
            selected={sender.id === selected?.id}
            onClick={() => setSelected(sender)}
          />
        ))}
    </div>
  );
};

export default PoolExitSenderSelector;
