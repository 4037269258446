import React from 'react';

import { DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import {
  OtherProductionImpactType,
  ProductionImpactType,
  PRODUCTION_IMPACT_TYPES,
  Props,
} from './SelectProductionImpactType.types';

const SelectProductionImpact = ({ selection, onSelectionChange, className }: Props) => {
  return (
    <DropDown
      className={className}
      placeholder="choisir un type d'impact..."
      selectedItem={selection}
      items={Object.values({
        ...PRODUCTION_IMPACT_TYPES,
        ...OtherProductionImpactType,
      })}
      keyValueExtractor={(searchResult: ProductionImpactType) => ({
        key: searchResult,
        value: searchResult,
      })}
      onSelectItem={(value: ProductionImpactType | undefined) => onSelectionChange(value)}
    />
  );
};

export default SelectProductionImpact;
