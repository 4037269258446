import React, { useCallback, useEffect, useState } from 'react';
import styles from './SecurityEventFormModal.module.scss';
import {
  Animation,
  Button,
  DatePickerInputDeprecated,
  FetchButton,
  ModalDeprecated,
  TextArea,
  TextInput,
  TimePicker,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory } from 'react-router-dom';
import { Props } from './SecurityEventFormModal.types';
import { Securite } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { OTHER_REASON } from '../Selects/SelectSecurityEventType/SelectSecurityEventType.component';
import SelectSecurityEventType from '../Selects/SelectSecurityEventType';
import { singleTextInputUnroll } from 'src/Services/Animations';
import classnames from 'classnames';
import PlanificationVisibilityToggle from 'src/Components/PlanificationVisibilityToggle';
import { EnumEntityActionType } from 'src/Services/API';
import { SECURITY_EVENTS } from '../Selects/SelectSecurityEventType/SelectSecurityEventType.enhanced';
import { toTimeInput } from 'src/Utils/date';

const SecurityEventFormModal = ({
  open,
  target,
  onValidate,
  fetchStatus,
  title,
  securityEvent,
}: Props) => {
  const [report, setReport] = useState(securityEvent?.report ?? '');
  const [showPlanification, setPlanificationVisibility] = useState(false);
  const [plannedDate, setPlannedDate] = useState<Date | undefined>(undefined);
  const [plannedTime, setPlannedTime] = useState<string>('');
  const [datePickerIsVisible, setDatePickerVisibility] = useState(false);
  const [reason, setReason] = useState(securityEvent?.subType);
  const [customReason, setCustomReason] = useState('');
  const [startDate, _] = useState(moment());

  const reset = useCallback(() => {
    setPlanificationVisibility(securityEvent !== undefined && securityEvent?.endDate !== undefined);
    setReport(securityEvent?.report ?? '');
    setPlannedDate(securityEvent?.endDate);
    setDatePickerVisibility(false);

    if (securityEvent?.endDate) {
      setPlannedTime(toTimeInput(securityEvent.endDate));
    }
    if (securityEvent?.subType) {
      if (Object.values(SECURITY_EVENTS).includes(securityEvent.subType as SECURITY_EVENTS)) {
        setReason(securityEvent.subType);
      } else {
        setReason(OTHER_REASON);
        setCustomReason(securityEvent.subType);
      }
    } else {
      setReason(undefined);
      setCustomReason('');
    }
  }, [securityEvent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [securityEvent]);

  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  const [hours, minutes] = plannedTime.split(':').map(value => parseInt(value, 10));

  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);

  return (
    <ModalDeprecated
      closeOnDocumentClick={!datePickerIsVisible}
      open={open ?? true}
      title={title}
      subtitle={target?.name}
      onClose={() => {
        onClose();
      }}
      icon={
        <div className={styles.modalIcon}>
          <Securite />
        </div>
      }
      footerActionsLeft={[<Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={fetchStatus}
          errorTitle="réessayer"
          disabled={
            !reason ||
            (reason === OTHER_REASON && !customReason) ||
            (showPlanification &&
              (!plannedDate ||
                !plannedTime ||
                moment(plannedDate).hours(hours).minutes(minutes).isBefore(moment())))
          }
          onClick={() => {
            onValidate({
              report,
              type: EnumEntityActionType.ACTION_SECURITY_EVENT,
              subType: reason === OTHER_REASON ? customReason : reason,
              startDate: startDate.toDate(),
              endDate:
                plannedDate && showPlanification
                  ? moment(plannedDate).hours(hours).minutes(minutes).toDate()
                  : undefined,
              // target is defined otherwise we would have redirected to "/"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              targetId: target!.id,
              localId: securityEvent?.localId,
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.typesSelector}>
        <WithLightTitle title="type d'événement">
          <SelectSecurityEventType
            className={classnames({ [styles.searchInputContainer]: reason === OTHER_REASON })}
            onSelectionChange={value => setReason(value)}
            selection={reason}
          />
          <Animation.Unroll visible={reason === OTHER_REASON} config={singleTextInputUnroll}>
            <TextInput
              containerClassName={styles.textInputContainer}
              value={customReason}
              onChange={event => setCustomReason((event.target as HTMLInputElement).value)}
              maxLength={45}
            />
          </Animation.Unroll>
        </WithLightTitle>
      </div>
      <div className={styles.reportHeader}>
        CR du {moment().format('DD/MM/YYYY')}
        <div className={styles.reportLength}>{report.length}/950 caractères</div>
      </div>
      <TextArea
        className={styles.textArea}
        placeholder="veuillez renseigner la raison."
        value={report}
        maxLength={950}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReport(event.target.value)}
      />
      <PlanificationVisibilityToggle
        className={styles.planificationButton}
        showPlanification={showPlanification}
        onClick={() => {
          if (showPlanification) {
            setPlannedDate(undefined);
            setPlannedTime('');
          } else {
            const plannedDateTime = startDate;
            if (moment().diff(startDate, 'minutes') === 0) {
              plannedDateTime.add(1, 'minutes');
            }

            setPlannedDate(plannedDateTime.toDate());
            setPlannedTime(plannedDateTime.format('HH:mm'));
          }
          setPlanificationVisibility(!showPlanification);
          setPlannedDate(new Date());
        }}
      />
      <Animation.Unroll visible={showPlanification}>
        <div className={styles.planification}>
          <WithLightTitle title="date d'échéance">
            <DatePickerInputDeprecated onSelectDate={setPlannedDate} date={plannedDate} />
          </WithLightTitle>
          <WithLightTitle title="heure">
            <TimePicker
              min={
                moment(plannedDate).isSame(moment(), 'day')
                  ? moment().add(1, 'minute').format('HH:mm')
                  : undefined
              }
              max={moment(plannedDate).isSame(moment(), 'day') ? '23:59' : undefined}
              value={plannedTime}
              onChange={event => {
                setPlannedTime((event.target as HTMLInputElement).value);
              }}
            />
          </WithLightTitle>
        </div>
      </Animation.Unroll>
    </ModalDeprecated>
  );
};

export default SecurityEventFormModal;
