import React from 'react';
import DisplayedSection from './DisplayedSection';
import styles from './MainPage.module.scss';
import SideBar from './SideBar';
import classnames from 'classnames';
import BottomMenuBar from './BottomMenuBar/BottomMenuBar.component';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const MainPage = () => {
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {!isMobile && <SideBar />}
      <DisplayedSection />
      {isMobile && <BottomMenuBar />}
    </div>
  );
};

export default MainPage;
