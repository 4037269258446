import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CroppedTarget } from 'src/Assets';
import { useFetchActionWithActionId } from 'src/Hooks/Actions';
import { useFetchCompanyContact } from 'src/Hooks/Contacts';

import styles from './ProductionImpactPreviewModal.module.scss';

const ProductionImpactPreviewModal = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const productionImpact = useFetchActionWithActionId(actionId ?? '');
  const targetContact = useFetchCompanyContact(productionImpact?.targetId ?? '');
  return (
    <ModalDeprecated
      title={"impact de production pour l'interlocuteur client"}
      subtitle={[targetContact?.firstName, targetContact?.name].join(' ')}
      open
      icon={
        <div className={styles.modalIcon}>
          <CroppedTarget />
        </div>
      }
      onClose={() => {
        history.goBack();
      }}
      footerActionsRight={[
        <Button.Primary onClick={() => history.goBack()}>fermer</Button.Primary>,
      ]}
    >
      <WithLightTitle title="type d'impact" className={styles.firstTextField}>
        <div className={styles.text}>{productionImpact?.subType}</div>
      </WithLightTitle>
      <WithLightTitle title="à la date du" className={styles.textField}>
        <div className={styles.text}>{moment(productionImpact?.endDate).format('L')}</div>
      </WithLightTitle>
      <WithLightTitle title="commentaires" className={styles.textField}>
        <div className={styles.text}>{productionImpact?.report}</div>
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default ProductionImpactPreviewModal;
