import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';
import { IllusWarning } from 'src/Assets';

import { Props } from './AbsenceDeletionWarningModal.types';

const AbsenceDeletionWarningModal = ({
  open,
  onClose,
  onDelete,
  onSuccess,
  fetchStatus,
}: Props) => {
  return (
    <ModalDeprecated
      onClose={onClose}
      title="Attention"
      open={open}
      icon={<IllusWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose} text="annuler" />,
        <FetchButton
          title="confirmer"
          fetchStatus={fetchStatus}
          onClick={onDelete}
          onSuccess={onSuccess}
        />,
      ]}
    >
      <div>
        Vous vous apprêtez à supprimer définitivement cette absence. Confirmez vous ce choix ?
      </div>
    </ModalDeprecated>
  );
};

export default AbsenceDeletionWarningModal;
