import {
  Button,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';

import { useHistory } from 'react-router-dom';
import { PoolExitHeader } from 'src/Assets';
import { Props } from './DeletePoolExitModal.types';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from '../../Utils/responsive';
import useDeleteScheduledPoolExit from '../../Hooks/Candidates/useDeleteScheduledPoolExit';
import { toFetchStatus } from '../../Services/ReactQuery';
import styles from './DeletePoolExitModal.module.scss';

const DeletePoolExitModal = ({ modalParameters }: Props) => {
  const history = useHistory();
  const isMobile = useMediaQuery(responsiveQuery);

  const deletePoolExit = useDeleteScheduledPoolExit();

  const deletePoolExitFetchStatus = toFetchStatus(deletePoolExit);

  return (
    <ModalDeprecated
      open
      icon={<PoolExitHeader />}
      title="sortie de pool"
      onClose={history.goBack}
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={history.goBack}>retour</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={history.goBack}>retour</Button.Secondary>
        ),
        <FetchButton
          key="exitFetchButton"
          title="annuler la sortie de pool"
          fetchStatus={deletePoolExitFetchStatus}
          onClick={() => {
            deletePoolExit.mutate({ id: modalParameters?.target?.id ?? '' });
          }}
          onSuccess={history.goBack}
          onError={history.goBack}
        />,
      ]}
    >
      <div
        className={styles.poolExitMessage}
      >{`souhaitez-vous réellement annuler la sortie de pool anticipée de ${modalParameters?.target?.name} ? `}</div>
    </ModalDeprecated>
  );
};

export default DeletePoolExitModal;
