import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  DatePickerInput,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ExportHeader } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { onlineManager } from '@tanstack/react-query';
import moment from 'moment';
import { useController } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useExportToDrive } from 'src/Hooks/Export/useExportToDrive';
import { ExportType } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import styles from './ExportModal.module.scss';
import { ExportModalSchema } from './ExportModal.types';

enum ExportTypeTitle {
  'ABSENCES' = 'absence',
  'EXCHANGES' = 'échange',
  'CLAIMS' = 'réclamation',
  'SECURITY' = 'sécurité',
  'PRODUCTION' = 'impact de production',
  'POOLEXIT' = 'sortie de pool',
}

const ExportModal = () => {
  const [isOnline, setIsOnline] = useState(onlineManager.isOnline());
  const history = useHistory();

  const shareToGDrive = useExportToDrive();

  const { control, handleSubmit, reset } = useFormWithZodResolver({
    schema: ExportModalSchema,
    defaultValues: {
      exportTypes: [],
      startDate: moment().subtract(1, 'month').toDate(),
      endDate: moment().toDate(),
    },
  });

  const exportTypes = useController({ name: 'exportTypes', control });
  const startingDate = useController({ name: 'startDate', control });
  const endingDate = useController({ name: 'endDate', control });

  const exportHandleSubmit = () =>
    handleSubmit(values => {
      shareToGDrive.mutate({
        types: values.exportTypes,
        startingDate: values.startDate,
        endingDate: values.endDate,
      });
      reset(values);
    });

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  });

  return (
    <ModalDeprecated
      title="export"
      subtitle="pour l'ensemble du compte"
      onClose={() => history.goBack()}
      icon={
        <div className={styles.modalIcon}>
          <ExportHeader />
        </div>
      }
      open
      footerActionsLeft={[
        <Button.Tertiary onClick={() => reset()}>tout réinitialiser</Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={history.goBack}>fermer</Button.Secondary>,
        <FetchButton
          title="valider"
          onClick={exportHandleSubmit()}
          disabled={!isOnline}
          fetchStatus={toFetchStatus(shareToGDrive)}
          onSuccess={history.goBack}
        />,
      ]}
    >
      <div className={styles.container}>
        <WithLightTitle title="périmètre à extraire">
          <div className={styles.checkbox}>
            <Checkbox
              checked={exportTypes.field.value.length === Object.values(ExportType).length}
              onChange={event =>
                exportTypes.field.onChange(event.target.checked ? Object.values(ExportType) : [])
              }
              label="tout sélectionner"
            />
          </div>
          <div className={styles.checkboxes}>
            {Object.values(ExportType).map(value => {
              return (
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={exportTypes.field.value.includes(value)}
                    onChange={event =>
                      exportTypes.field.onChange(
                        event.target.checked
                          ? [...exportTypes.field.value, value]
                          : exportTypes.field.value.filter(item => item !== value)
                      )
                    }
                    label={ExportTypeTitle[value].toLowerCase()}
                  />
                </div>
              );
            })}
          </div>
          <ErrorMessage error={exportTypes.fieldState.error} />
        </WithLightTitle>
        <div className={styles.line}>
          <WithLightTitle title="date de début">
            <DatePickerInput
              selected={startingDate.field.value}
              onChange={startingDate.field.onChange}
              isClearable={false}
            />
            <ErrorMessage error={startingDate.fieldState.error} />
          </WithLightTitle>
          <WithLightTitle title="date de fin">
            <DatePickerInput
              selected={endingDate.field.value}
              onChange={endingDate.field.onChange}
              isClearable={false}
            />
            <ErrorMessage error={endingDate.fieldState.error} />
          </WithLightTitle>
        </div>
      </div>
    </ModalDeprecated>
  );
};

export default ExportModal;
