import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ClosePop,
  IllusTdPHistory,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import UnderContructionSection from 'src/Components/UnderContructionSection';
import { ROLE } from 'src/Redux/Types';
import AbsencesHistory from './AbsencesHistory';
import ClaimsHistory from './ClaimsHistory';
import ExchangeHistory from './ExchangeHistory';
import styles from './HistoryModal.module.scss';
import { LocationState } from '../../Services/Routing';

import { ACTIONS, Props } from './HistoryModal.types';
import ProductionImpactHistory from './ProductionImpactHistory';
import SecurityHistory from './SecurityHistory';
import SegmentedMenu from './SegmentedMenu';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import HistoryModalHeader from './HistoryModalHeader/HistoryModalHeader.component';
import HistoryMobileModal from './HistoryMobileModal/HistoryMobileModal.component';

export const historyTab = {
  [ACTIONS.ECHANGES]: 'échanges',
  [ACTIONS.RECLAMATIONS]: 'réclamations',
  [ACTIONS.ABSENCES]: 'absences',
  [ACTIONS.SECURITE]: 'sécurité',
  [ACTIONS.PRODUCTION_IMPACT]: 'impact de production',
  [ACTIONS.AUTRES]: 'autres',
};

const HistoryModal = (props: Props) => {
  const params = useParams<{ historySection: ACTIONS }>();
  const [selectedTab, setSelectedTab] = useState(params.historySection);
  const target = props.modalParameters?.target;
  const history = useHistory();
  const location = useLocation<LocationState>();
  const isMobile = useMediaQuery(responsiveQuery);
  const displayedHistorySection = () => {
    switch (selectedTab) {
      case ACTIONS.ECHANGES:
        return <ExchangeHistory target={target} />;
      case ACTIONS.RECLAMATIONS:
        return <ClaimsHistory target={target} />;
      case ACTIONS.ABSENCES:
        return <AbsencesHistory candidate={target} />;
      case ACTIONS.SECURITE:
        return <SecurityHistory target={target} />;
      case ACTIONS.PRODUCTION_IMPACT:
        return <ProductionImpactHistory target={target} />;
      case ACTIONS.AUTRES:
        return (
          <div className={styles.underConstruction}>
            <UnderContructionSection />
          </div>
        );
      default:
        break;
    }
  };
  return (
    <>
      {isMobile ? (
        <HistoryMobileModal
          target={target}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          onClose={props.onClose}
          displayedSection={displayedHistorySection()}
        />
      ) : (
        <>
          <div className={styles.overlay} onClick={props.onClose} />
          <div className={styles.container}>
            <IllusTdPHistory className={styles.illus} />
            <Button.Tertiary
              onClick={() => {
                props.onClose();
              }}
              className={styles.closeButton}
            >
              <ClosePop />
            </Button.Tertiary>
            <div className={styles.title}>historique des actions</div>
            <HistoryModalHeader target={target} />
            <SegmentedMenu
              selected={selectedTab}
              controls={Object.values(ACTIONS).filter(
                action =>
                  !(target?.role === ROLE.CANDIDATE && action === ACTIONS.PRODUCTION_IMPACT) &&
                  !(
                    target?.role === ROLE.CONTACT &&
                    [ACTIONS.ABSENCES, ACTIONS.SECURITE].includes(action)
                  )
              )}
              onSelectionChange={menuItem => {
                setSelectedTab(menuItem);
                history.push({
                  pathname: `/history/${menuItem}`,
                  state: { modalParameters: { target }, background: location.state?.background },
                });
              }}
              keyValueExtractor={menuItem => ({
                key: menuItem,
                value: historyTab[menuItem],
              })}
            />
            {displayedHistorySection()}
          </div>
        </>
      )}
    </>
  );
};

export default HistoryModal;
