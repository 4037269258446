import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Flux,
  Helmet,
  PeopleAbsent,
  People,
  Reundo,
  Thunder,
  Megaphone,
  ListCheck,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Props } from 'src/Containers/ListItemActions/ListItemActions.types';
import AssessmentModal from 'src/Containers/AssessmentModal';
import { ROLE } from 'src/Redux/Types';
import { ROUTES } from 'src/Services/Routing';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import { CandidateStatusEnum } from '../../../Services/API';

const ListItemActionsContent = ({ target, onItemClick }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery(responsiveQuery);
  const openRecrutlive = () => {
    window.open(
      `${process.env.REACT_APP_RECRUTLIVE_URL}/candidate/${target?.id}/file/synthese/identity`,
      '_blank',
      'noopener,noreferrer'
    );
  };
  return (
    <>
      <PopupMenu.Item
        icon={<Flux />}
        text="échanges"
        onClick={() => {
          history.push({
            pathname: ROUTES.EXCHANGE_NEW,
            state: { modalParameters: { target }, background: location },
          });
          onItemClick?.();
        }}
      />
      {target?.role === ROLE.CANDIDATE && (
        <PopupMenu.Item
          icon={<Helmet />}
          text="sécurité"
          onClick={() => {
            history.push({
              pathname: ROUTES.SECURITY_EVENT_NEW,
              state: { modalParameters: { target }, background: location },
            });
            onItemClick?.();
          }}
        />
      )}
      {target?.role === ROLE.CANDIDATE && (
        <>
          <PopupMenu.Item
            icon={<PeopleAbsent />}
            text="absence"
            onClick={() => {
              history.push({
                pathname: ROUTES.ABSENCE_NEW,
                state: { modalParameters: { target }, background: location },
              });
              onItemClick?.();
            }}
          />
          {!isMobile && (
            <PopupMenu.Item icon={<People />} text="dossier intérimaire" onClick={openRecrutlive} />
          )}
          {target.candidateStatus?.id === CandidateStatusEnum.INT && (
            <>
              {target.isScheduledExit ? (
                <>
                  <PopupMenu.Item
                    icon={<Reundo />}
                    text="modifier la sortie du pool"
                    onClick={() => {
                      history.push({
                        pathname: ROUTES.POOL_EXIT,
                        state: { modalParameters: { target }, background: location },
                      });
                      onItemClick?.();
                    }}
                  />
                  <PopupMenu.Item
                    icon={<Trashcan />}
                    text="annuler la sortie du pool"
                    onClick={() => {
                      history.push({
                        pathname: ROUTES.DELETE_POOL_EXIT,
                        state: { modalParameters: { target }, background: location },
                      });
                      onItemClick?.();
                    }}
                  />
                </>
              ) : (
                <PopupMenu.Item
                  icon={<Reundo />}
                  text="sortir du pool"
                  onClick={() => {
                    history.push({
                      pathname: ROUTES.POOL_EXIT,
                      state: { modalParameters: { target }, background: location },
                    });
                    onItemClick?.();
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      {target?.role === ROLE.CONTACT && (
        <>
          <PopupMenu.Item
            icon={<Thunder />}
            text="impact de production"
            onClick={() => {
              history.push({
                pathname: ROUTES.PRODUCTION_IMPACT_NEW,
                state: { modalParameters: { target }, background: location },
              });
              onItemClick?.();
            }}
          />
          <AssessmentModal target={target} />
        </>
      )}
      <PopupMenu.Item
        icon={<Megaphone />}
        text="réclamations"
        onClick={() => {
          history.push({
            pathname: ROUTES.CLAIM_NEW,
            state: { modalParameters: { target }, background: location },
          });
          onItemClick?.();
        }}
      />
      <PopupMenu.Item
        icon={<ListCheck />}
        text="historique des actions"
        onClick={() => {
          history.push({
            pathname: '/history/exchanges',
            state: { modalParameters: { target }, background: location },
          });
          onItemClick?.();
        }}
      />
    </>
  );
};
export default ListItemActionsContent;
