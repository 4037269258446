import React, { useState } from 'react';

import { Props } from './AbsencesHistory.types';
import styles from './AbsencesHistory.module.scss';
import {
  useDeleteAbsence,
  useFetchAbsenceReasons,
  useFetchCandidateAbsences,
} from 'src/Hooks/Absence';
import HistoryCard from 'src/Components/HistoryCard';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { FETCH_STATUS } from 'src/Redux/Types';
import { useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { Button, toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { LocationState, ROUTES } from 'src/Services/Routing';
import { useFetchCompanyServices } from 'src/Hooks/Companies';
import AbsenceActions from './AbsenceActions';
import AbsenceDeletionWarningModal from 'src/Components/AbsenceDeletionWarningModal';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const AbsencesHistory = ({ candidate }: Props) => {
  const companyId = useSelector(getCurrentCompanyId) ?? '';
  const candidateAbsencesQuery = useFetchCandidateAbsences(candidate?.id ?? '', companyId);
  const candidateAbsences = candidateAbsencesQuery.data ?? [];
  const absencesReasons = useFetchAbsenceReasons();
  const servicesQuery = useFetchCompanyServices();
  const servicesQueryFetchStatus = toFetchStatus(servicesQuery);

  const fetchStatus = toFetchStatus(candidateAbsencesQuery);
  const history = useHistory();
  const location = useLocation<LocationState>();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedAbsenceId, setSelectedAbsenceId] = useState<string | undefined>(undefined);
  const useDeleteAbsenceMutation = useDeleteAbsence();
  const deleteFetchStatus = toFetchStatus(useDeleteAbsenceMutation.mutation);
  const onDeleteSelected = (absenceId: string) => {
    setSelectedAbsenceId(absenceId);
    setOpenDeleteModal(true);
  };
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <div className={styles.container}>
      <Button.Primary
        className={styles.button}
        onClick={() =>
          history.push({
            pathname: ROUTES.ABSENCE_NEW,
            state: {
              modalParameters: { target: candidate },
              background: location.state.background,
            },
          })
        }
      >
        ajouter une absence
      </Button.Primary>
      <div className={isMobile ? styles.mobileAbsencesContainer : styles.absencesContainer}>
        {(fetchStatus === FETCH_STATUS.PENDING ||
          servicesQueryFetchStatus === FETCH_STATUS.PENDING) &&
          new Array(6).fill(undefined).map(_ => {
            return (
              <div className={styles.loaderSpace}>
                <HistoryCard.Loading />
              </div>
            );
          })}
        {fetchStatus === FETCH_STATUS.FULFILLED &&
          servicesQueryFetchStatus === FETCH_STATUS.FULFILLED && (
            <>
              {candidateAbsences.length === 0 ? (
                <div className={styles.noResult}>Aucune absence pour cet intérimaire</div>
              ) : (
                <AutoSizer>
                  {({ height, width }: { height: number; width: number }) => (
                    <FixedSizeList
                      itemCount={candidateAbsences.length}
                      width={width}
                      className={styles.list}
                      height={height}
                      itemSize={isMobile ? 128 : 100}
                    >
                      {({ index, style }) => {
                        const absence = candidateAbsences[index];
                        return (
                          <div style={style} className={styles.cardContainer}>
                            <HistoryCard
                              key={absence.absenceId}
                              title={`date début ${moment(absence.startDate).format(
                                'L'
                              )} - fin le ${moment(absence.endDate).format('L')}`}
                              subtitle={
                                absencesReasons.find(
                                  absencesReason => absencesReason.id === absence.absenceReason
                                )?.label ?? absence.absenceReason
                              }
                              secondSubtitle={
                                servicesQuery.data?.find(
                                  service => service.serviceId === absence.serviceId
                                )?.name ?? candidate?.service?.name
                              }
                              onClick={() =>
                                history.push({
                                  pathname: ROUTES.ABSENCE_EDIT,
                                  state: {
                                    modalParameters: {
                                      target: candidate,
                                      contentId: absence.localId,
                                    },
                                    background: location.state.background,
                                  },
                                })
                              }
                              rightElement={
                                candidate &&
                                absence && (
                                  <AbsenceActions
                                    candidate={candidate}
                                    absenceId={absence.absenceId}
                                    absenceLocalId={absence.localId}
                                    onDeleteSelected={onDeleteSelected}
                                  />
                                )
                              }
                            />
                          </div>
                        );
                      }}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              )}
            </>
          )}
      </div>
      <AbsenceDeletionWarningModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        fetchStatus={deleteFetchStatus}
        onSuccess={() => {
          setOpenDeleteModal(false);
          toast.success("l'absence a bien été supprimée", { autoClose: 5000 });
        }}
        onDelete={() => selectedAbsenceId && useDeleteAbsenceMutation.delete(selectedAbsenceId)}
      />
    </div>
  );
};

export default AbsencesHistory;
