import React, { useCallback, useEffect, useState } from 'react';

import { Props } from './ClaimCreationModal.types';
import styles from './ClaimCreationModal.module.scss';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ROLE } from 'src/Redux/Types';
import {
  Button,
  DatePickerInputDeprecated,
  FetchButton,
  ModalDeprecated,
  TextArea,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import SelectCandidate from '../Selects/SelectCandidate';
import {
  CandidateOnMission,
  ClaimCategory,
  CompanyContact,
  OSMCompanyModelCompanyDtoCandidateDto as OSMCandidate,
  OSMRepositoriesModelClaimCategoryRepositoryDtoClaimReasonDto as ClaimReason,
  TargetType,
} from 'src/Services/API';
import SelectClaimCategory from '../Selects/SelectClaimCategory';
import SelectClaimReason from '../Selects/SelectClaimReason';
import { CutFinger } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SelectContact from '../Selects/SelectContact';
import { useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'src/Redux/Companies/Selectors';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const ClaimCreationModal = ({ modalParameters, fetchStatus, onValidate, open }: Props) => {
  const [comment, setComment] = useState('');
  const [candidate, setCandidate] = useState<CandidateOnMission | null>(null);
  const [contact, setContact] = useState<CompanyContact | null>(null);
  const [createdAt, setCreatedAt] = useState<Date | undefined>(new Date());
  const [closed, setClosed] = useState(false);
  const [category, setCategory] = useState<ClaimCategory | undefined>(undefined);
  const [reason, setReason] = useState<ClaimReason | undefined>(undefined);
  const reset = useCallback(() => {
    setComment('');
    setCandidate(null);
    setContact(null);
    setCreatedAt(new Date());
    setClosed(false);
    setCategory(undefined);
    setReason(undefined);
    // eslint-disable-next-line
  }, []);

  const target = modalParameters?.target;
  const currentCompanyId = useSelector(getCurrentCompanyId);

  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  // Redirect user to app root if he navigates directly to modal url on accident
  useEffect(() => {
    if (target === undefined) {
      history?.push('/');
    }
  }, [target, history]);
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <ModalDeprecated
      icon={<CutFinger />}
      title={`déclarer une réclamation ${
        target?.role === ROLE.CANDIDATE ? 'intérimaire' : 'client'
      }`}
      open={open ?? true}
      onClose={() => {
        reset();
        onClose();
      }}
      footerActionsLeft={[
        <Button.Tertiary onClick={reset}>
          {isMobile ? 'réinitialiser' : 'tout réinitialiser'}
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={onClose}>annuler</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={onClose}>annuler</Button.Secondary>
        ),
        <FetchButton
          title="valider"
          disabled={!category || !reason || !createdAt}
          errorTitle="réessayer"
          fetchStatus={fetchStatus}
          onClick={() => {
            const osmCandidate: OSMCandidate =
              target?.role === ROLE.CANDIDATE
                ? {
                    id: target.id,
                    name: target.name,
                  }
                : {
                    id: candidate?.candidateId,
                    firstName: candidate?.firstName,
                    name: candidate?.name,
                  };
            onValidate({
              type: target?.role === ROLE.CANDIDATE ? TargetType.TT : TargetType.Compte,
              companyId: currentCompanyId,
              opening: moment(createdAt).toDate(),
              closing: closed ? new Date() : undefined,
              category,
              reason: reason,
              customerContact:
                target?.role === ROLE.CONTACT
                  ? { id: target.id, name: target.name }
                  : {
                      id: contact?.contactId,
                      name: contact?.name,
                      firstName: contact?.firstName,
                      civility: contact?.civility?.label,
                      position: contact?.position,
                    },
              candidate: osmCandidate,
              comment,
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      {target?.role === ROLE.CONTACT ? (
        <WithLightTitle title="interlocuteur">
          <div className={styles.headerText}>{`${target?.name} - ${target?.position}`}</div>
        </WithLightTitle>
      ) : (
        <WithLightTitle title="intérimaire">
          <div
            className={styles.headerText}
          >{`${target?.name} - ${target?.qualification?.name}`}</div>
        </WithLightTitle>
      )}
      <WithLightTitle
        title={`${target?.role === ROLE.CONTACT ? 'intérimaire' : 'interlocuteur'} (optionnel)`}
        className={styles.searchField}
      >
        {target?.role === ROLE.CONTACT ? (
          <SelectCandidate
            selectedCandidate={candidate}
            onCandidateSelect={selectedCandidate => {
              setCandidate(selectedCandidate);
            }}
          />
        ) : (
          <SelectContact
            selectedContact={contact}
            onContactSelect={selectedContact => {
              setContact(selectedContact);
            }}
            canBeReset
            defaultValue="nom d'une personne..."
          />
        )}
      </WithLightTitle>
      <WithLightTitle title="catégorie de réclamation" className={styles.searchField}>
        <SelectClaimCategory
          candidateCategories={target?.role === ROLE.CANDIDATE}
          contactCategories={target?.role === ROLE.CONTACT}
          selectedClaimCategory={category}
          onClaimCategorySelect={selectedClaimCategory => {
            setCategory(selectedClaimCategory);
            setReason(undefined);
          }}
        />
      </WithLightTitle>
      <WithLightTitle title="cause de la réclamation" className={styles.searchField}>
        <SelectClaimReason
          selectedClaimCategory={category}
          selectedClaimReason={reason}
          onClaimReasonSelect={selectedClaimReason => {
            setReason(selectedClaimReason);
          }}
          disabled={category === undefined}
        />
      </WithLightTitle>
      <WithLightTitle title="commentaire" className={styles.searchField}>
        <TextArea
          className={styles.textArea}
          value={comment}
          maxLength={950}
          placeholder="détails sur la réclamation"
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setComment(event.target.value)
          }
        />
      </WithLightTitle>
      <WithLightTitle title="date d'ouverture">
        <DatePickerInputDeprecated
          onSelectDate={setCreatedAt}
          date={createdAt}
          maxDate={closed ? new Date() : undefined}
        />
      </WithLightTitle>

      <div className={styles.closedClaim}>
        <div className={styles.closedClaimedText}>marquer la réclamation comme fermée</div>
        <ToggleSwitch
          checked={closed}
          onCheckStatusChange={closed => {
            setClosed(closed);
          }}
        />
      </div>
      {closed === true && createdAt && createdAt > new Date() && (
        <div className={styles.errorMessage}>
          vous ne pouvez pas créer une réclamation fermée avec une date d'ouverture postérieure à la
          date du jour
        </div>
      )}
    </ModalDeprecated>
  );
};

export default ClaimCreationModal;
