import React from 'react';

import { Props } from './CompanyContactCard.types';
import styles from './CompanyContactCard.module.scss';
import Card from '..';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import MobileCard from '../MobileCard/MobileCard.component';

const CompanyContactCard = (props: Props) => {
  const isMobile = useMediaQuery(responsiveQuery);
  return isMobile ? (
    <MobileCard {...props} descriptionClassname={styles.companyContact} />
  ) : (
    <Card {...props} descriptionClassname={styles.companyContact} />
  );
};

export default CompanyContactCard;
