import React, { useEffect } from 'react';
import { Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import history from 'src/Utils/history';
import PrivateRoute from './Components/PrivateRoute';
import AbsenceCreationModal from './Containers/AbsenceCreationModal';
import EnhancedCallback from './Containers/Callback';
import ClaimCreationModal from './Containers/ClaimCreationModal';
import ExchangeCreationModal from './Containers/ExchangeCreationModal';
import EnhancedLogin from './Containers/Login/Login.enhanced';
import MainPage from './Containers/MainPage';
import PerimeterSelectionModal from './Containers/PerimeterSelectionModal';
import ProductionImpactCreationModal from './Containers/ProductionImpactCreationModal';
import SecurityEventCreationModal from './Containers/SecurityEventCreationModal';
import { setupAPI } from './Services/API/APIConfigurator';
import { LocationState, ROUTES, SingleRouteMap } from './Services/Routing';
import { useMediaQuery } from '@mui/material';

import HistoryModal from './Containers/HistoryModal';
import { useServiceWorker } from './Hooks/ServiceWorker/useServiceWorker';
import CommunicationMatrixEditionModal from './Containers/CommunicationMatrixEditionModal';
import CommunicationMatrixInsertionModal from './Containers/CommunicationMatrixInsertionModal';
import ExchangeEditionModal from './Containers/ExchangeEditionModal';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import ExchangePreviewModal from './Containers/ExchangePreviewModal';
import ProductionImpactPreviewModal from './Containers/ProductionImpactPreviewModal';
import SecurityEventPreviewModal from './Containers/SecurityEventPreviewModal';
import ExportModal from './Containers/ExportModal';
import AbsenceEditionModal from './Containers/AbsenceEditionModal';
import ProductionImpactEditionModal from './Containers/ProductionImpactEditionModal';
import { toast, ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import SecurityEventEditionModal from './Containers/SecurityEventEditionModal';
import ClaimEditionModal from './Containers/ClaimEditionModal';
import PoolExitModal from './Containers/PoolExitModal';
import { useDispatch, useSelector } from 'react-redux';
import { userPreferencesActions } from './Redux/UserPreferences/Slice';
import {
  responsiveQuery,
  sectionFromDesktopToMobile,
  sectionFromMobileToDesktop,
} from './Utils/responsive';
import { getDisplaySection } from './Redux/UserPreferences/Selectors';
import DeletePoolExitModal from './Containers/DeletePoolExitModal';

const modalRouteMap: SingleRouteMap[] = [
  {
    route: ROUTES.EXCHANGE_EDIT,
    component: ExchangeEditionModal,
  },
  {
    route: ROUTES.EXCHANGE_NEW,
    component: ExchangeCreationModal,
  },
  {
    route: ROUTES.EXCHANGE_VIEW(':actionId'),
    component: ExchangePreviewModal,
  },
  {
    route: ROUTES.CLAIM_EDIT,
    component: ClaimEditionModal,
  },
  {
    route: ROUTES.CLAIM_NEW,
    component: ClaimCreationModal,
  },
  {
    route: ROUTES.SECURITY_EVENT_NEW,
    component: SecurityEventCreationModal,
  },
  {
    route: ROUTES.SECURITY_EVENT_EDIT,
    component: SecurityEventEditionModal,
  },
  {
    route: ROUTES.SECURITY_EVENT_VIEW(':actionId'),
    component: SecurityEventPreviewModal,
  },
  {
    route: ROUTES.PERIMETER_SELECT,
    component: PerimeterSelectionModal,
  },
  {
    route: ROUTES.ABSENCE_EDIT,
    component: AbsenceEditionModal,
  },
  {
    route: ROUTES.ABSENCE_NEW,
    component: AbsenceCreationModal,
  },
  {
    route: ROUTES.PRODUCTION_IMPACT_EDIT,
    component: ProductionImpactEditionModal,
  },
  {
    route: ROUTES.PRODUCTION_IMPACT_NEW,
    component: ProductionImpactCreationModal,
  },
  {
    route: ROUTES.PRODUCTION_IMPACT_VIEW(':actionId'),
    component: ProductionImpactPreviewModal,
  },
  {
    route: ROUTES.HISTORY,
    component: HistoryModal,
  },
  {
    route: '/export',
    component: ExportModal,
  },
  {
    route: ROUTES.COMMUNICATION_MATRIX_INSERT,
    component: CommunicationMatrixInsertionModal,
  },
  {
    route: ROUTES.COMMUNICATION_MATRIX_EDIT,
    component: CommunicationMatrixEditionModal,
  },
  {
    route: ROUTES.POOL_EXIT,
    component: PoolExitModal,
  },
  {
    route: ROUTES.DELETE_POOL_EXIT,
    component: DeletePoolExitModal,
  },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push('/');
  }

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/login" component={EnhancedLogin} />
        <Route exact path="/callback" component={EnhancedCallback} />
        <PrivateRoute exact path="/" component={MainPage} />
      </Switch>

      {modalRouteMap.map(modalInfo => (
        <PrivateRoute
          key={modalInfo.route}
          exact
          path={modalInfo.route}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}
    </>
  );
}

function App() {
  const serviceWorker = useServiceWorker();
  useInitializeAnalytics();

  useEffect(() => {
    setupAPI();
  }, []);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(responsiveQuery);

  const displayedSection = useSelector(getDisplaySection);
  useEffect(() => {
    dispatch(
      userPreferencesActions.setDisplaySection(
        isMobile
          ? sectionFromDesktopToMobile(displayedSection)
          : sectionFromMobileToDesktop(displayedSection)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  useEffect(() => {
    if (serviceWorker?.isUpdateAvailable) {
      toast.info(
        "Une nouvelle version de l'application est disponible ! Cliquez ici pour la mettre à jour",
        {
          autoClose: false,
          onClose: () => {
            serviceWorker?.updateAssets();
            window.location.reload();
          },
        }
      );
    }
  }, [serviceWorker?.isUpdateAvailable, serviceWorker?.updateAssets, serviceWorker]);

  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
