import React, { useEffect, useRef, useState } from 'react';

import { Props } from './WorkplaceHeader.types';
import styles from './WorkplaceHeader.module.scss';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/Services/Routing';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import { useSection } from 'src/Hooks/Section/useSection';
import { animate } from 'src/Utils/animate';
import { getDisplaySection } from 'src/Redux/UserPreferences/Selectors';
import { TypeAndEnter } from '@randstad-lean-mobile-factory/react-components-core';
import { DISPLAYED_SECTION } from 'src/Redux/UserPreferences/Types';

const WorkplaceHeader = ({
  title,
  comment,
  rightNode,
  shareNode,
  doAnimation = false,
  selectedValue,
  setSelectedValue,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const section = useSection();
  const selectedAgency = useSelector(getCurrentAgency);
  const selectedBrand = useSelector(getCurrentBrand);
  const displayedSection = useSelector(getDisplaySection);
  const isMobile = useMediaQuery(responsiveQuery);
  const expandedHeaderRef = useRef<HTMLDivElement>(null);
  const contractedHeaderRef = useRef<HTMLDivElement>(null);
  const contractedHeaderTextRef = useRef<HTMLParagraphElement>(null);
  const [isContractedHeader, setIsContractedHeader] = useState(true);

  useEffect(() => {
    setIsContractedHeader(
      (expandedHeaderRef.current?.getBoundingClientRect().bottom ?? 0) >
        (contractedHeaderRef.current?.getBoundingClientRect().bottom ?? 0)
    );
  }, [section?.scrollTop]);

  return (
    <>
      {!isMobile && (
        <p className={styles.appNameAndBrand}>
          {'tour de production / '}
          <span
            onClick={() => {
              history.push({ pathname: ROUTES.PERIMETER_SELECT, state: { background: location } });
            }}
            className={styles.currentUnitAndBrand}
          >{`${selectedBrand?.brand?.name} - ${selectedAgency}`}</span>
        </p>
      )}
      {doAnimation && (
        <div
          className={styles.contractedHeader}
          ref={contractedHeaderRef}
          style={
            doAnimation
              ? {
                  marginTop: `${animate(-2, 0, section?.titleKeyframe ?? 0)}rem`,
                }
              : {}
          }
        >
          <p
            ref={contractedHeaderTextRef}
            className={classnames(styles.contractedHeaderText, {
              [styles.isContractedHeaderText]: isContractedHeader,
            })}
          >
            {title}
          </p>
        </div>
      )}

      <div
        className={classnames(styles.header, { [styles.mobileHeader]: isMobile })}
        ref={expandedHeaderRef}
        style={
          doAnimation
            ? {
                marginBottom: `${animate(0.75, -0.25, section?.titleKeyframe ?? 0)}rem`,
                marginTop: `${animate(0, -1.5, section?.titleKeyframe ?? 0)}rem`,
              }
            : {}
        }
      >
        <p className={styles.title}>{title}</p>
        <div className={styles.subHeader}>
          <div>{shareNode}</div>
          <div>{rightNode}</div>
        </div>
      </div>
      {!isMobile && (
        <>
          <WithLightTitle title="commentaires">
            <div className={styles.commentAndSearch}>
              <div className={styles.text}>{comment}</div>
              {displayedSection === DISPLAYED_SECTION.INTERIM && setSelectedValue && (
                <div className={styles.searchBar}>
                  <TypeAndEnter
                    selectedValue={selectedValue ?? ''}
                    setSelectedValue={setSelectedValue}
                    placeholder="Rechercher"
                  />
                </div>
              )}
            </div>
          </WithLightTitle>
          <div className={styles.separator} />
        </>
      )}
    </>
  );
};

export default WorkplaceHeader;
