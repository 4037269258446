import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from '../../Services/Routing';
import { Export } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

const ShareToGDrive = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  return (
    <Button
      variant="tertiary"
      onClick={() =>
        history.push({
          pathname: '/export',
          state: {
            background: location,
          },
        })
      }
    >
      <Export />
    </Button>
  );
};

export default ShareToGDrive;
