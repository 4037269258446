import React, { useCallback, useEffect, useState } from 'react';

import { Props } from './PerimeterSelectionModal.types';
import styles from './PerimeterSelectionModal.module.scss';
import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTdpUnite } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory } from 'react-router-dom';
import SelectPerimeter from '../Selects/SelectPerimeter';
import SelectCompany from '../Selects/SelectCompany';
import { useFetchDefaultCompanyForPerimeter } from 'src/Hooks/Companies';
import { onlineManager } from '@tanstack/react-query';

const PerimeterSelectionModal = ({
  open,
  selectedAgency,
  selectedBrand,
  selectedCompany,
  onValidate,
}: Props): JSX.Element => {
  const [brand, setBrand] = useState(selectedBrand);
  const [agency, setAgency] = useState(selectedAgency);
  const [company, setCompany] = useState(selectedCompany);
  const [isOnline, setIsOnline] = useState(onlineManager.isOnline());
  const reset = useCallback(() => {
    setBrand(selectedBrand);
    setAgency(selectedAgency);
    setCompany(selectedCompany);
  }, [selectedBrand, selectedAgency, selectedCompany]);
  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  const { isSuccess, data, mutate } = useFetchDefaultCompanyForPerimeter();
  useEffect(() => {
    isSuccess && setCompany(data);
  }, [data, isSuccess, setCompany]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  });
  return (
    <ModalDeprecated
      open={open ?? true}
      title={`choisissez vos unités`}
      onClose={() => {
        onClose();
      }}
      icon={<IllusTdpUnite />}
      footerActionsLeft={[<Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>]}
      footerActionsRight={[
        <Button.Secondary onClick={() => onClose()}>annuler</Button.Secondary>,
        <Button
          disabled={!brand || !agency || !company || !isOnline}
          onClick={() => {
            onValidate(brand, agency, company);
            onClose();
          }}
        >
          valider
        </Button>,
      ]}
    >
      <div className={styles.title}>
        Sélectionnez parmi votre périmètre, l'unité et le compte voulu
      </div>
      <WithLightTitle title="périmètre">
        <div className={styles.perimeterSelectionRow}>
          <SelectPerimeter
            selectedAgency={agency}
            selectedBrand={brand}
            setSelectedAgency={selectedAgency => {
              setAgency(selectedAgency);
              mutate({
                agencyId: selectedAgency ?? '',
                brandCode: brand?.brandCodeApiHeader ?? '',
              });
            }}
            setSelectedBrand={selectedBrand => {
              setBrand(selectedBrand);
              setAgency(selectedBrand?.agencies?.[0]);
              mutate({
                agencyId: selectedBrand?.agencies?.[0] ?? '',
                brandCode: brand?.brandCodeApiHeader ?? '',
              });
            }}
          />
        </div>
      </WithLightTitle>
      <WithLightTitle title="compte" className={styles.companySelection}>
        <SelectCompany
          selectedCompany={company}
          setSelectedCompany={selectedCompany => {
            setCompany(selectedCompany);
          }}
          agencies={[agency ?? '']}
          brandCode={brand?.brandCodeApiHeader ?? ''}
        />
      </WithLightTitle>
      {!isOnline && (
        <div className={styles.errorMessage}>
          vous êtes hors ligne, vous ne pouvez pas changer de périmètre en étant hors-ligne
        </div>
      )}
    </ModalDeprecated>
  );
};

export default PerimeterSelectionModal;
