import React, { useRef, useState } from 'react';

import { Props } from './ListItemActions.types';
import {
  Button,
  ModalDeprecated,
  PopupActions,
  PopupMenu,
  PopupMenuContext,
} from '@randstad-lean-mobile-factory/react-components-core';

import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import styles from './ListItemActions.module.scss';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import { useSelector } from 'react-redux';
import { getCardDisplayMode } from 'src/Redux/UserPreferences/Selectors';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import ListItemActionsContent from './ListItemActionsContent/ListItemActionsContent.component';

const ListItemActions = ({ target }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const displayMode = useSelector(getCardDisplayMode);
  const isMobile = useMediaQuery(responsiveQuery);
  const mobileModalRef = useRef<PopupActions>(null);
  const deviceWidth = window.innerWidth;
  return (
    <>
      {isMobile ? (
        <ModalDeprecated
          title={<></>}
          onOpen={() => setIsOpened(true)}
          onClose={() => setIsOpened(false)}
          ref={mobileModalRef}
          contentStyle={{ width: deviceWidth - 32 }}
          hideCloseAction
          footerActionsRight={[
            <Button.Tertiary
              className={styles.mobileButton}
              onClick={() => {
                mobileModalRef.current?.close();
              }}
            >
              annuler
            </Button.Tertiary>,
          ]}
          trigger={
            <Button.Tertiary
              className={classnames(
                styles.button,
                {
                  [styles.mobileGridButton]: displayMode === DISPLAY_MODE.GRID,
                },
                { [styles.openButton]: isOpened }
              )}
            >
              <ThreeDots className={styles.menuIcon} />
            </Button.Tertiary>
          }
        >
          <PopupMenuContext.Provider value={() => ({ actions: null })}>
            <div className={styles.globalModalContainer}>
              <div className={styles.modalContent}>
                <ListItemActionsContent
                  target={target}
                  onItemClick={() => {
                    mobileModalRef.current?.close();
                  }}
                />
              </div>
            </div>
          </PopupMenuContext.Provider>
        </ModalDeprecated>
      ) : (
        <PopupMenu
          position={'left top'}
          width={290}
          onOpen={() => setIsOpened(true)}
          onClose={() => setIsOpened(false)}
          trigger={
            <Button.Tertiary
              className={classnames(styles.button, { [styles.openButton]: isOpened })}
            >
              <ThreeDots className={styles.menuIcon} />
            </Button.Tertiary>
          }
        >
          <ListItemActionsContent target={target} />
        </PopupMenu>
      )}
    </>
  );
};

export default ListItemActions;
