import React from 'react';

import styles from './AssessmentModal.module.scss';
import {
  Button,
  ModalDeprecated,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTransfert, Like } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { usePerimeter } from 'src/Hooks/Perimeter';
import { Props } from './AssessmentModal.types';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const AssessmentModal = ({ target }: Props) => {
  const { companyId, brandCode, agencyId } = usePerimeter();
  const company = useSelector(getCurrentCompany);

  const createAssessment = () => {
    window.open(
      `${process.env.REACT_APP_SATISFACTION_URL}?company=${companyId}${
        target?.id ? `&contact=${target.id}` : ''
      }&brand=${brandCode}&agency=${agencyId}`,
      '_newtab'
    );
  };
  const isMobile = useMediaQuery(responsiveQuery);

  return (
    <ModalDeprecated
      title="transfert de vos données de tdp vers un nouveau bilan"
      trigger={
        !isMobile ? (
          <PopupMenu.Item keepOpenOnClick icon={<Like />} text="créer un bilan" />
        ) : undefined
      }
      icon={
        <div className={styles.modalIcon}>
          <IllusTransfert />
        </div>
      }
      footerActionsRight={[
        <Button.Primary disabled={!companyId} onClick={createAssessment}>
          lancer le bilan
        </Button.Primary>,
      ]}
    >
      <div>
        Vous allez faire un bilan avec {target?.name}. Il s'agit du compte {company?.companyName} de
        l'unité {agencyId}.
      </div>
    </ModalDeprecated>
  );
};

export default AssessmentModal;
