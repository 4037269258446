import { Button, Checkbox, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { DISPLAYED_SECTION, DISPLAY_MODE, SORT_BY } from 'src/Redux/UserPreferences/Types';
import styles from './FiltersMenuContent.module.scss';
import { useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplaySection, getOnMissionOnlyFilter } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { Props } from './FiltersMenuContent.types';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import { Cards, List } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { menuContext } from '@randstad-lean-mobile-factory/react-components-core/dist/Menus/Menu/Menu.types';

const FiltersMenuContent = ({
  setLocalDisplayMode,
  setLocalSortBy,
  setMobileLocalOnMissionOnly,
  localDisplayMode,
  localSortBy,
  mobileDisplayedSection,
  mobileLocalOnMissionOnly,
}: Props) => {
  const context = useContext(menuContext);
  const dispatch = useDispatch();
  const onMissionOnly = useSelector(getOnMissionOnlyFilter);
  const [localOnMissionOnly, setLocalOnMissionOnly] = useState(onMissionOnly);
  const onDisplayModeChange = useCallback(
    (displayMode: DISPLAY_MODE) => {
      dispatch(userPreferencesActions.setCardDisplayMode(displayMode));
    },
    [dispatch]
  );
  const onMissionOnlyChange = useCallback(
    (onMissionOnly: boolean) => {
      dispatch(userPreferencesActions.setOnMissionOnlyFilter(onMissionOnly));
    },
    [dispatch]
  );
  const onSortByChange = useCallback(
    (sortBy: SORT_BY) => {
      dispatch(userPreferencesActions.setSortBy(sortBy));
    },
    [dispatch]
  );
  const displaySection = useSelector(getDisplaySection);
  const isMobile = useMediaQuery(responsiveQuery);
  return (
    <>
      <div className={styles.title}>type d'affichage</div>
      <MenuItem
        selected={localDisplayMode === DISPLAY_MODE.GRID}
        onClick={() => setLocalDisplayMode(DISPLAY_MODE.GRID)}
        keepOpenOnClick
      >
        <Cards />
        cartes
      </MenuItem>
      <MenuItem
        selected={localDisplayMode === DISPLAY_MODE.LIST}
        onClick={() => setLocalDisplayMode(DISPLAY_MODE.LIST)}
        keepOpenOnClick
      >
        <List />
        liste
      </MenuItem>

      {(displaySection === DISPLAYED_SECTION.INTERIM ||
        mobileDisplayedSection === DISPLAYED_SECTION.INTERIM) && (
        <>
          <div className={styles.title}>trier par</div>

          <MenuItem
            selected={localSortBy === SORT_BY.ALPHABETICAL}
            onClick={() => setLocalSortBy(SORT_BY.ALPHABETICAL)}
            keepOpenOnClick
          >
            ordre alphabétique
          </MenuItem>
          <MenuItem
            selected={localSortBy === SORT_BY.QUALIFICATION}
            onClick={() => setLocalSortBy(SORT_BY.QUALIFICATION)}
            keepOpenOnClick
          >
            qualification
          </MenuItem>

          <div className={styles.title}>filtres</div>
          <div className={styles.checkBox}>
            <Checkbox
              checked={isMobile ? mobileLocalOnMissionOnly : localOnMissionOnly}
              onChange={() =>
                isMobile
                  ? setMobileLocalOnMissionOnly?.(!mobileLocalOnMissionOnly)
                  : setLocalOnMissionOnly(!localOnMissionOnly)
              }
            >
              <p className={styles.checkboxLabel}>TT en poste uniquement</p>
            </Checkbox>
          </div>
        </>
      )}

      {!isMobile && (
        <Button
          onClick={() => {
            onDisplayModeChange(localDisplayMode);
            onSortByChange(localSortBy);
            onMissionOnlyChange(localOnMissionOnly);
            context?.close();
          }}
        >
          valider
        </Button>
      )}
    </>
  );
};

export default FiltersMenuContent;
