import React, { useCallback, useEffect, useState } from 'react';

import { Props } from './CommunicationMatrixFormModal.types';
import styles from './CommunicationMatrixFormModal.module.scss';
import {
  Button,
  DropDown,
  ExclusiveSelectionGroup,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTdpHeaderPoursuivre } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory } from 'react-router-dom';
import { CompanyContact, EnumCommunicationMatrixEntryFrequencyUnit } from 'src/Services/API';
import { frequencyUnitEnumToKeyValue } from 'src/Services/Transformers/communicationMatrix';
import { useFetchCommunicationMatrixContacts } from 'src/Hooks/CommunicationMatrix';
import SelectContact from 'src/Containers/Selects/SelectContact';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';

const MAX_FREQUENCY = 5;

const CommunicationMatrixFormModal = ({
  onValidate,
  fetchStatus,
  open,
  title,
  entry,
  contactSelectDisabled,
}: Props) => {
  const isMobile = useMediaQuery(responsiveQuery);
  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  const [contact, setContact] = useState<CompanyContact | null>(
    entry !== undefined
      ? {
          contactId: entry?.contactId,
          name: entry?.contactName,
          firstName: entry?.contactFirstName,
        }
      : null
  );
  const [frequency, setFrequency] = useState<string | undefined>(entry?.frequency?.toString());
  const [frequencyUnit, setFrequencyUnit] = useState<
    EnumCommunicationMatrixEntryFrequencyUnit | undefined
  >(entry?.frequencyUnit);

  useEffect(() => {
    setFrequency(entry?.frequency?.toString());
  }, [entry?.frequency]);

  useEffect(() => {
    setFrequencyUnit(entry?.frequencyUnit);
  }, [entry?.frequencyUnit]);

  useEffect(() => {
    setContact(
      entry !== undefined
        ? {
            contactId: entry?.contactId,
            name: entry?.contactName,
            firstName: entry?.contactFirstName,
          }
        : null
    );
  }, [entry?.contactName, entry?.contactFirstName, entry?.contactId, entry]);

  const communicationMatrixContacts = useFetchCommunicationMatrixContacts();

  return (
    <ModalDeprecated
      open={open ?? true}
      title={title}
      onClose={() => {
        onClose();
      }}
      icon={<IllusTdpHeaderPoursuivre />}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setContact(
              entry !== undefined
                ? {
                    contactId: entry?.contactId,
                    name: entry?.contactName,
                    firstName: entry?.contactFirstName,
                  }
                : null
            );
            setFrequencyUnit(entry?.frequencyUnit);
            setFrequency(entry?.frequency?.toString());
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={() => onClose()}>annuler</Button.Secondary>,
        <FetchButton
          disabled={contact === undefined || frequency === undefined || frequencyUnit === undefined}
          fetchStatus={fetchStatus}
          onClick={() => {
            onValidate({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              contactId: contact!.contactId!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              frequency: parseInt(frequency!),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              frequencyUnit: frequencyUnit!,
              markedForDeletion: false,
              version: entry?.version ?? 0,
            });
          }}
          title="valider"
          errorTitle="réessayer"
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.form}>
        <WithLightTitle title={'interlocuteur'}>
          <SelectContact
            selectedContact={contact}
            onContactSelect={selectedContact => {
              setContact(selectedContact);
            }}
            contactIdBlackList={communicationMatrixContacts}
            disabled={contactSelectDisabled === true}
          />
        </WithLightTitle>
        <div className={classnames({ [styles.formLine]: !isMobile })}>
          <WithLightTitle title={'fréquence (nombre de fois)'}>
            <ExclusiveSelectionGroup
              selected={frequency ? { key: frequency, label: frequency } : undefined}
              setSelected={kl => setFrequency(kl?.key)}
              values={Array(MAX_FREQUENCY)
                .fill(null)
                .map((_, index) => ({
                  key: (index + 1).toString(),
                  label: (index + 1).toString(),
                }))}
              getKey={item => item.key}
              getStringValue={item => item.label}
            />
          </WithLightTitle>
          <WithLightTitle
            title={'par ...'}
            className={classnames(styles.dropdown, { [styles.mobileDropDownContainer]: isMobile })}
          >
            <DropDown
              selectedItem={frequencyUnit}
              canBeReset={false}
              placeholder="sélectionnez"
              items={Object.values(EnumCommunicationMatrixEntryFrequencyUnit)}
              keyValueExtractor={(frequencyUnit: EnumCommunicationMatrixEntryFrequencyUnit) =>
                frequencyUnitEnumToKeyValue(frequencyUnit)
              }
              onSelectItem={setFrequencyUnit}
            />
          </WithLightTitle>
        </div>
      </div>
    </ModalDeprecated>
  );
};

export default CommunicationMatrixFormModal;
