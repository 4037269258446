import { useState } from 'react';

import { Button, Menu } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { getCardDisplayMode, getSortBy } from 'src/Redux/UserPreferences/Selectors';
import FiltersMenuContent from './FiltersMenuContent/FiltersMenuContent.component';
import { Parameters } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import styles from './FiltersMenu.module.scss';

const FiltersMenu = () => {
  const displayMode = useSelector(getCardDisplayMode);
  const sortBy = useSelector(getSortBy);

  const [localSortBy, setLocalSortBy] = useState(sortBy);
  const [localDisplayMode, setLocalDisplayMode] = useState(displayMode);

  return (
    <Menu
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Button variant="tertiary">
          <Parameters />
        </Button>
      }
      className={styles.menu}
    >
      <FiltersMenuContent
        localDisplayMode={localDisplayMode}
        localSortBy={localSortBy}
        setLocalDisplayMode={setLocalDisplayMode}
        setLocalSortBy={setLocalSortBy}
      />
    </Menu>
  );
};

export default FiltersMenu;
