import React, { useRef, useState } from 'react';

import { Props } from './ParametersPopup.types';
import styles from './ParametersPopup.module.scss';
import {
  Button,
  ParametersPopup as PP,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { CircleRandstad } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ROUTES } from 'src/Services/Routing';
import { Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

const ParametersPopup = (props: Props) => {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const popup = useRef<PopoverActions>(null);
  const location = useLocation();

  return (
    <PP
      trigger={
        <div>
          <Button.Tertiary
            className={classnames(styles.gearButton, { [styles.openGearButton]: isOpened })}
          >
            <Settings className={isOpened ? styles.gearIcon : undefined} />
          </Button.Tertiary>
        </div>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      ref={popup}
      {...props}
    >
      <div
        className={styles.menuItem}
        onClick={() => {
          popup?.current?.close();
          history.push({ pathname: ROUTES.PERIMETER_SELECT, state: { background: location } });
        }}
      >
        <CircleRandstad />
        <div className={styles.spacer} />
        choix de vos unités
      </div>
      <div className={styles.version}>{`version :  ${process.env.REACT_APP_VERSION}`}</div>
    </PP>
  );
};

export default ParametersPopup;
