import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Securite } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFetchActionWithActionId } from 'src/Hooks/Actions';
import { useFetchCompanyCandidate } from 'src/Hooks/Candidates';

import styles from './SecurityEventPreviewModal.module.scss';

const SecurityEventPreviewModal = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const securityEvent = useFetchActionWithActionId(actionId ?? '');
  const targetCandidate = useFetchCompanyCandidate(securityEvent?.targetId ?? '');
  return (
    <ModalDeprecated
      title={'événement de sécurité'}
      subtitle={[targetCandidate?.firstName, targetCandidate?.name].join(' ')}
      onClose={() => history.goBack()}
      icon={
        <div className={styles.modalIcon}>
          <Securite />
        </div>
      }
      open
      footerActionsRight={[
        <Button.Primary onClick={() => history.goBack()}>fermer</Button.Primary>,
      ]}
    >
      <div className={styles.content}>
        <WithLightTitle title="type d'événement">{securityEvent?.subType}</WithLightTitle>
        <WithLightTitle title="commentaires">{securityEvent?.report}</WithLightTitle>
        <WithLightTitle title="planification">
          {moment(securityEvent?.endDate).format('L')}
        </WithLightTitle>
      </div>
    </ModalDeprecated>
  );
};

export default SecurityEventPreviewModal;
