import React from 'react';

import { Props } from './CandidateListItem.types';
import styles from './CandidateListItem.module.scss';
import ListItem from 'src/Components/ListItem';
import MobileListItem from '../MobileListItem/MobileListItem.component';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';

const CandidateListItem = (props: Props) => {
  const isMobile = useMediaQuery(responsiveQuery);
  return isMobile ? (
    <MobileListItem {...props} descriptionClassname={styles.candidate} />
  ) : (
    <ListItem {...props} descriptionClassname={styles.candidate} />
  );
};

export default CandidateListItem;
