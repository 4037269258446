import {
  Button,
  ComboBox,
  DatePickerInputDeprecated,
  FetchButton,
  ModalDeprecated,
  TextArea,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PoolExitHeader } from 'src/Assets';
import PoolExitSenderSelector from 'src/Containers/PoolExitModal/PoolExitSenderSelector';
import useUpdateCandidateSubscription from 'src/Hooks/Candidates/useUpdateCandidateSubscription';
import useFetchCGC from 'src/Hooks/Repositories/useFetchCGC';
import { CGC, IdLabel, UpdateSubscriptionEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';
import SelectPoolMotivation from '../Selects/SelectPoolMotivation/SelectPoolMotivation.component';
import styles from './PoolExitModal.module.scss';
import { Props } from './PoolExitModal.types';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import SelectService from '../Selects/SelectService';
import moment from 'moment';
import useFetchPoolExitData from '../../Hooks/Candidates/useFetchPoolExitData';
import useFetchPoolMotivations from '../../Hooks/Repositories/useFetchPoolMotivations';
import { useFetchCompanyServices } from '../../Hooks/Companies';

const PoolExitModal = ({ modalParameters }: Props) => {
  const history = useHistory();

  const fetchCGC = useFetchCGC();
  const cgcFetchStatus = toFetchStatus(fetchCGC);

  const { data: poolExitData, isSuccess } = useFetchPoolExitData(
    modalParameters?.target?.id ?? '',
    {
      enabled: modalParameters?.target?.isScheduledExit,
    }
  );
  const { data: motivations } = useFetchPoolMotivations();
  const servicesQuery = useFetchCompanyServices();

  const [serviceId, setServiceId] = useState<string | undefined>(
    modalParameters?.target?.service?.id
  );
  const [motivation, setMotivation] = useState<IdLabel | undefined>(undefined);
  const [motivationComment, setMotivationComment] = useState<string | undefined>(undefined);
  const [sender, setSender] = useState<IdLabel | undefined>(undefined);
  const [isRepositionable, setIsRepositionable] = useState<boolean>(false);
  const [forward, setForward] = useState(false);
  const [cgc, setCGC] = useState<CGC | undefined>(undefined);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [poolExitDate, setPoolExitDate] = useState<Date>(new Date());
  const isMobile = useMediaQuery(responsiveQuery);
  const updateCandidateSubscription = useUpdateCandidateSubscription(
    modalParameters?.target?.id ?? '',
    moment(poolExitDate).add(1, 'd').toDate()
  );

  const candidateSubscriptionFetchStatus = toFetchStatus(updateCandidateSubscription);

  const reset = () => {
    setMotivation(undefined);
    setSender(undefined);
    setIsRepositionable(false);
    setForward(false);
    setCGC(undefined);
    setComment(undefined);
    setPoolExitDate(new Date());
  };

  useEffect(() => {
    if (poolExitData) {
      const cgc = fetchCGC.data?.find(cgc => cgc.id === poolExitData.cgcId);
      const service = servicesQuery.data?.find(service => service.name === poolExitData.service);
      setIsRepositionable(poolExitData.isRepositionable);
      setServiceId(service?.serviceId);
      setMotivationComment(poolExitData.motivationComment);
      setPoolExitDate(poolExitData.poolExitDate ?? new Date());
      setMotivation(motivations?.find(motivation => motivation.id === poolExitData.motivationId));
      setSender({ id: poolExitData.senderId, label: '' });
      if (cgc) {
        setForward(true);
        setCGC(cgc);
        setComment(poolExitData.comment);
      }
    }
  }, [fetchCGC.data, isSuccess, motivations, poolExitData, servicesQuery.data]);

  useEffect(() => {
    if (!forward) {
      setCGC(undefined);
      setComment(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forward]);
  return (
    <ModalDeprecated
      open
      icon={<PoolExitHeader />}
      title="sortie de pool"
      onClose={history.goBack}
      footerActionsLeft={[
        <Button.Tertiary key="resetButton" onClick={reset}>
          {isMobile ? 'réinitialiser' : 'tout réinitialiser'}
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        isMobile ? (
          <Button.Tertiary onClick={history.goBack}>annuler</Button.Tertiary>
        ) : (
          <Button.Secondary onClick={history.goBack}>annuler</Button.Secondary>
        ),
        <FetchButton
          key="exitFetchButton"
          disabled={!serviceId || !motivation || !sender || (forward && !cgc?.id)}
          title="valider"
          fetchStatus={candidateSubscriptionFetchStatus}
          onClick={() => {
            if (serviceId && motivation && sender) {
              const target = modalParameters?.target;
              updateCandidateSubscription.mutate({
                actionType: UpdateSubscriptionEnum.Unsubscribe,
                motivationId: motivation.id,
                senderId: sender.id,
                isRepositionable,
                isScheduledExit: true,
                motivationComment,
                cgcId: cgc?.id,
                poolExitDate,
                comment,
                serviceId,
                firstContractDate: target?.firstContractDate
                  ? new Date(target.firstContractDate).toISOString()
                  : undefined,
                targetGroup: target?.targetGroup,
              });
            }
          }}
          onSuccess={history.goBack}
          onError={history.goBack}
        />,
      ]}
    >
      <WithLightTitle className={styles.section} title="service auquel l'intérimaire est rattaché">
        <SelectService selected={serviceId} onChange={setServiceId} />
      </WithLightTitle>
      <WithLightTitle className={styles.section} title="motif de sortie">
        <SelectPoolMotivation
          selectedMotivation={motivation}
          setSelectedMotivation={setMotivation}
        />
      </WithLightTitle>
      <WithLightTitle
        title="commentaires sur le motif de sortie"
        className={styles.section}
        titleClassName={styles.titleContainer}
        rightTitleComponent={
          <div className={styles.textLength}>
            {pluralFormat(motivationComment?.length ?? 0, 'caractère')}
          </div>
        }
      >
        <TextArea
          className={styles.comment}
          placeholder="précisez pourquoi ce candidat doit être sorti du pool"
          value={motivationComment}
          onChange={event => setMotivationComment((event.target as HTMLTextAreaElement).value)}
        />
      </WithLightTitle>
      <WithLightTitle className={styles.section} title="sortie à l’initiative de">
        <PoolExitSenderSelector selected={sender} setSelected={setSender} />
      </WithLightTitle>

      <WithLightTitle className={styles.section} title="date de sortie anticipée">
        <DatePickerInputDeprecated
          date={poolExitDate}
          onSelectDate={e => {
            e && setPoolExitDate(e);
          }}
        />
      </WithLightTitle>

      <div className={styles.switchContainer}>
        <div className={styles.switchLabel}>l’intérimaire est considéré repositionnable</div>
        <ToggleSwitch
          checked={isRepositionable}
          onCheckStatusChange={value => {
            setIsRepositionable(value);
          }}
        />
      </div>
      <div className={styles.switchContainer}>
        <div className={styles.switchLabel}>transmettre la candidature à un CGC</div>
        <ToggleSwitch
          checked={forward}
          onCheckStatusChange={value => {
            setForward(value);
          }}
        />
      </div>
      {forward && (
        <>
          <WithLightTitle title="CGC (*obligatoire)" className={styles.section}>
            <ComboBox
              id="cgc"
              disableClearable
              minLengthToSearch={0}
              fetchStatus={cgcFetchStatus}
              value={cgc}
              searchResults={fetchCGC.data ?? []}
              useNativeOptionFiltering
              keyValueExtractor={cgc => ({
                key: cgc.id ?? '',
                value: [cgc.label, cgc.id].join(' - ') ?? '',
              })}
              onChange={setCGC}
              placeholder="choisissez un CGC"
            />
          </WithLightTitle>
          <WithLightTitle
            title="commentaires destinés au CGC"
            className={styles.section}
            titleClassName={styles.titleContainer}
            rightTitleComponent={
              <div className={styles.textLength}>
                {pluralFormat(comment?.length ?? 0, 'caractère')}
              </div>
            }
          >
            <TextArea
              className={styles.comment}
              placeholder="précisez pourquoi cette candidature doit être étudiée par un CGC"
              value={comment}
              onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
            />
          </WithLightTitle>
        </>
      )}
    </ModalDeprecated>
  );
};

export default PoolExitModal;
