import React, { useState, useMemo, useEffect } from 'react';

import { Props } from './CandidateResults.types';
import styles from './CandidateResults.module.scss';

import AlphabetPicker from 'src/Components/AlphabetPicker';
import FiltersMenu from 'src/Components/FiltersMenu/FiltersMenu.component';
import Grid from 'src/Components/Grid';
import List from 'src/Components/List';
import { Element } from 'react-scroll';
import WorkplaceHeader from 'src/Components/WorkplaceHeader';
import { useIsFetching } from '@tanstack/react-query';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { Binocular } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { responsiveQuery } from 'src/Utils/responsive';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { useSection } from 'src/Hooks/Section/useSection';
import { ListItemWithRole } from 'src/Components/ListItem/ListItem.types';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import ShareToGDrive from 'src/Components/ShareToGDrive';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';

const CandidateResults = ({ candidates, cardDisplayMode }: Props) => {
  const isMobile = useMediaQuery(responsiveQuery);
  const selectedCompany = useSelector(getCurrentCompany);
  const selectedAgency = useSelector(getCurrentAgency);
  const ItemRenderer = cardDisplayMode === DISPLAY_MODE.GRID ? Grid : List;
  const titleAdditionalText = selectedCompany
    ? ` chez ${selectedCompany?.companyName} - ${selectedCompany?.companyAddress?.postalCode} (${selectedCompany?.siret}) `
    : '';
  const section = useSection();
  const isFetchingCandidates = useIsFetching(['fetchAllCandidates']) > 0;
  const [searchValue, setSearchValue] = useState('');

  const searchedInterim: ListItemWithRole[] = useMemo(
    () =>
      searchValue !== '' && candidates !== undefined
        ? candidates.filter(candidate =>
            `${candidate.contactDetail.title}`.toLowerCase().includes(searchValue.toLowerCase())
          )
        : candidates ?? [],
    [searchValue, candidates]
  );

  useEffect(() => setSearchValue(''), [selectedAgency]);

  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {!isMobile && (
        <div className={styles.headerSection}>
          <WorkplaceHeader
            title={`trouver un intérimaire ${titleAdditionalText}`}
            comment="Retrouvez l'ensemble des intérimaires inscrits sur votre unité"
            rightNode={<FiltersMenu />}
            shareNode={<ShareToGDrive />}
            selectedValue={searchValue}
            setSelectedValue={newValue => setSearchValue(newValue)}
          />
        </div>
      )}
      {candidates === undefined || isFetchingCandidates ? (
        <Loader size="medium" />
      ) : candidates.length > 0 ? (
        <div className={classnames(styles.contactBook, { [styles.mobileBook]: isMobile })}>
          <Element
            id="listScrollableContainer"
            name="listScrollableContainer"
            className={styles.contactList}
            onScroll={() => {
              section?.setScrollTop(
                document.getElementById('listScrollableContainer')?.scrollTop ?? 0
              );
            }}
          >
            <ItemRenderer items={searchedInterim} />
          </Element>
          <div
            className={classnames(styles.alphabetPickerContainer, {
              [styles.mobileAlphabetPickerContainer]: isMobile,
            })}
          >
            <AlphabetPicker />
          </div>
        </div>
      ) : (
        <div>
          <Binocular />
          <div className={styles.noResult}>Aucun résultat pour intérimaire</div>
        </div>
      )}
    </div>
  );
};

export default CandidateResults;
